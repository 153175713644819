<template>
    <div class="templateCon">
        <iframe :src="iframeSrc"></iframe>
    </div>
</template>

<script>
export default {
    data() {
        return {
            iframeSrc: '',
        };
    },
    created() {
        this.iframeSrc = this.$route.meta.iframeSrc;
    },
    watch: {
        $route(to) {
            this.iframeSrc = to.meta.iframeSrc;
        },
    },
};
</script>

<style  lang="stylus" scoped>
.templateCon
  width 100%
  height 100%
  iframe
    width 100%
    height 100%
    border none
</style>